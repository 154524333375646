import MafiaCharecter from "../Mafia/MafiaCharecter";
import Hero from "../common/Hero";
import MafiaRoom from "../common/MafiaRoom";
import Fetuare from "../common/Fetuare";
import JoinBot from "../common/JoinBot";
import Team from "../Teams/Team";

const Home = () => {
    return (<div className="main">
        {/* ***** Header Start ***** */}
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="index.html">
                        <img src="assets/img/logo/logo.png" alt="Brand Logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item active">
                            <a href="/" className="nav-link active">Home</a>
                        </li>
                    </ul>
                    {/* Navbar Toggler */}
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                    {/* Navbar Action Button */}
                    
                </div>
            </nav>
        </header>
        <Hero />

        <Fetuare />

        <MafiaRoom />
        
        <MafiaCharecter/>
        
        <Team/>
        <br/>
        <br/>
        <br/>
        <JoinBot/>
        
        <footer className="footer-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                        {/* Footer Items */}
                        <div className="footer-items">
                            {/* Copyright Area */}
                            <div className="copyright-area py-4">  Design and Front-END and Back-END and Bot Power By : <a href="https://github.com/artafps/" target="_blank">artafps</a></div>
                        </div>
                        {/* Scroll To Top */}
                        <div id="scroll-to-top" className="scroll-to-top">
                            <a href="#header" className="smooth-anchor">
                                <svg className="svg-inline--fa fa-arrow-up" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg><path fill="currentColor" d="M374.6 246.6C368.4 252.9 360.2 256 352 256s-16.38-3.125-22.62-9.375L224 141.3V448c0 17.69-14.33 31.1-31.1 31.1S160 465.7 160 448V141.3L54.63 246.6c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160C387.1 213.9 387.1 234.1 374.6 246.6z" /></svg>{/* <i class="fa-solid fa-arrow-up"></i> Font Awesome fontawesome.com */}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/*====== Footer Area End ======*/}
        {/*====== Modal Search Area Start ======*/}
        <div id="search" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Search <svg className="svg-inline--fa fa-circle-xmark icon-close" aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg><path fill="currentColor" d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" /></svg>{/* <i class="far fa-times-circle icon-close"></i> Font Awesome fontawesome.com */}
                    </div>
                    <div className="modal-body">
                        <form className="row">
                            <div className="col-12 align-self-center">
                                <div className="row">
                                    <div className="col-12 pb-3">
                                        <h2 className="search-title mt-0 mb-3">What are you looking for?</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 input-group mt-md-4">
                                        <input type="text" placeholder="Enter your keywords" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 input-group align-self-center">
                                        <button className="btn btn-bordered-white mt-3">Search</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {/*====== Modal Search Area End ======*/}
    </div>
    );
}

export default Home;