import { Fragment } from "react";

const Hero = () => {
    return (<Fragment>
       
        <section className="hero-section">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-6 col-lg-9 text-center">
                        {/* Hero Content */}
                        <div className="hero-content">
                            <div className="intro text-center mb-5">
                                <span className="intro-text">Ham Bot</span>
                                <h1 className="mt-4">خوش آمدید به وب سایت ربات چند کاره</h1>
                                <p>این ربات برای رفاه و سرگرمی شما کاربران عزیز ساخته شد <br /> ممنونیم از اینکه از ما حمایت میکنید</p>
                            </div>
                            {/* Buttons */}
                            <div className="button-group">
                                <a className="btn btn-bordered active " href="https://t.me/hlproxyBot"><i className="icon-rocket mr-2" /> ورود به بات </a>
                                <br/>
                                <br/>
                                <a className="btn btn-bordered-white" href="https://t.me/HL_PROXY"><i className="icon-note mr-2" /> ورود چنل پروکسی و توییت </a>
                                <a className="btn btn-bordered-white" href="https://t.me/sillyangles"><i className="icon-note mr-2" /> °⊥° ورود چنل   °⊥° زوایای احمقانه  </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </Fragment>);
}

export default Hero;