import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../Config.json'
const MafiaRoom = () => {
    const [Data, setData] = useState([]);
    const getData = () => {
        axios.get(config.api + '/api/get/room/mafia', {
            "accept": "application/json",
            "Content-Type": "application/json"
        }).then(res => { 
            setData(res.data)
            setTimeout(() => {
                getData()
            }, 10000);
        }).catch(err => { console.log(err) })
    }
    useEffect(() => {
        getData()
    }, []);
    return (<section id="explore" className="project-area">
        <div className="container">
            <div className="row" style={{ direction: 'rtl', textAlign: "right" }}>
                <div className="col-12">
                    {/* Intro */}
                    <div className="intro d-flex justify-content-between align-items-end m-0">
                        <div className="intro-content">
                            <span className="intro-text"> لابی عمومی </span>
                            <h3 className="mt-3 mb-0">بازی مافیا </h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-slides">
                <div className="swiper-container slider-mid items swiper-container-initialized swiper-container-horizontal">
                    <div className="swiper-wrapper " style={{ display: 'flex', justifyContent: 'center' }} >
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            loop={true}
                            slidesPerView={'auto'}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 100,
                                modifier: 2.5,
                            }}
                            pagination={{ el: '.swiper-pagination', clickable: true }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                                clickable: true,
                            }}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            className="swiper_container"
                        >
                            {Data.length > 0 ? Data.map(item => {
                                return (
                                    <SwiperSlide><div className="swiper-slide item swiper-slide-active" data-swiper-slide-index={1} style={{ width: 370, margin: "auto" }}>
                                        <div className="card project-card">
                                            <div className="media">
                                                <a href={`https://telegram.me/hlproxyBot?start=join_${item.TidLobby}`}>
                                                    <img className="card-img-top avatar-max-lg" src="assets/img/content/download.jpg" alt />
                                                </a>
                                                <div className="media-body ml-4">
                                                    <a href={`https://telegram.me/hlproxyBot?start=join_${item.TidLobby}`}>
                                                        <h4 className="m-0">{item.TidLobby}</h4>
                                                    </a>

                                                </div>
                                            </div>
                                            {/* Project Body */}
                                            <div className="card-body">
                                                <div className="countdown-times" style={{ direction: 'rtl', textAlign: "right" }}>
                                                    <h5 className="my-2">افراد داخل لابی</h5>
                                                    <div className="countdown d-flex" data-date="2022-04-25" style={{ display: 'none' }} />
                                                </div>
                                                <div className="items" style={{ direction: 'rtl', textAlign: "right" }}>
                                                    {/* Single Item */}
                                                    {item.allMember.map(item => {
                                                        return (
                                                            <div className="single-item" >
                                                                <span>{item.name}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="item-progress">
                                                    <div className="progress mt-4 mt-md-5">
                                                        <div className="progress-bar" role="progressbar" style={{ width: String((item.allMember.length / parseInt(item.maxMember)) * 100) + "%", fontSize: 18 }} aria-valuenow={item.allMember.length} aria-valuemin={0} aria-valuemax={item.maxMember}>{item.allMember.length}/{item.maxMember}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Project Footer */}
                                            <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                                <a className="btn btn-bordered-white btn-smaller" href={`https://telegram.me/hlproxyBot?start=join_${item.TidLobby}`}>ورود به لابی</a>
                                                {/* Social Share */}

                                            </div>
                                            {/* Blockchain Icon */}
                                            <div className="blockchain-icon">
                                                <img src="assets/img/content/binance.png" alt />
                                            </div>
                                        </div>
                                    </div></SwiperSlide>
                                )
                            }) : (<SwiperSlide><div className="swiper-slide item swiper-slide-active" data-swiper-slide-index={1} style={{ width: 370, margin: "auto" }} >
                            <div className="card project-card" style={{ direction: 'rtl', textAlign: "right" }} >
                                <p>
                                    لابی خالی وجود ندارد میتوانید خودتان از طریق ربات لابی را بسازید
                                </p>
                            </div>
                        </div></SwiperSlide>)}


                        </Swiper>



                    </div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true" /></div>
            </div>
        </div>
    </section>);
}

export default MafiaRoom;