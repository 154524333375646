const Fetuare = () => {
  return (<section className="content-area" style={{ direction: 'rtl', textAlign: 'right' }}>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6">
          <div className="content intro">
            <span className="intro-text">کاربرد های ربات</span>
            <h2> ربات خدمات زیر را در اختیار شما میگذارد</h2>
            <ul className="list-unstyled items mt-5">
              <li className="item">
                {/* Content List */}
                <div className="content-list d-flex align-items-center">
                  <div className="content-icon">
                    <span>
                    <img width={40} height={40} src="assets/img/content/download.png"/>
                    </span>
                  </div>
                  <div className="content-body mr-4">
                    <h3 className="m-0">دانلودر اینستا</h3>
                    <p className="mt-3">دانلود ویدیو های اینستاگرام</p>
                  </div>
                </div>
              </li>
              <li className="item">
                {/* Content List */}
                <div className="content-list d-flex align-items-center">
                  <div className="content-icon">
                    <span>
                    <img width={40} height={40} src="assets/img/content/chat.png"/>
                    </span>
                  </div>
                  <div className="content-body mr-4">
                    <h3 className="m-0">چت ناشناس</h3>
                    <p className="mt-3">با هم دیگه چت ناشناس داشته باشید</p>
                  </div>
                </div>
              </li>
              <li className="item">
                {/* Content List */}
                <div className="content-list d-flex align-items-center">
                  <div className="content-icon">
                    <span>
                    <img width={40} height={40} src="assets/img/content/mafia.png"/>
                    </span>
                  </div>
                  <div className="content-body mr-4">
                    <h3 className="m-0">بازی مافیا</h3>
                    <p className="mt-3">با هم دیگه مافیا بازی کنید</p>
                  </div>
                </div>
              </li>
              <li className="item">
                {/* Content List */}
                <div className="content-list d-flex align-items-center">
                  <div className="content-icon">
                    <span>
                    <img width={40} height={40} src="assets/img/content/information.png"/>
                    </span>
                  </div>
                  <div className="content-body mr-4">
                    <h3 className="m-0">چالش اطلاعات</h3>
                    <p className="mt-3">داخل گروه ها یا پی وی هم دیگه بهم سوال حل کنید و سکه بگیرید</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6">
          {/* Blockchain Animation */}
          <div className="wrapper-animation d-none d-md-block">
              <img src="assets/img/content/iPhone15.png"/>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Fetuare;