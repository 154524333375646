import axios from "axios";
import { useEffect, useState } from "react";

const Team = () => {
    const [AllUser, setAllUser] = useState([]);

        const data = [
            'https://t.me/Seyed3yed',
            'https://t.me/NIksA2020',
            'https://t.me/ARTAFPS'
        ]
        

    return (<section className="team-area p-0">
        <div className="container">
            <div className="row" style={{ direction: "rtl" }}>
                <div className="col-12">
                    {/* Intro */}
                    <div className="intro d-flex justify-content-between align-items-end m-0">
                        <div className="intro-content">
                            <h3 className="mt-3 mb-0">تیم تستر ربات</h3>
                        </div>

                    </div>
                </div>
            </div>
            {/* Team Card */}
            <div className="row" style={{ direction: "rtl" }}>
                <div className="card team-card text-center m-2 col-12 col-md-6 col-lg-4">
                    <a href="https://t.me/Seyed3yed">
                        <img className="mx-auto" src="./../man.png" alt />
                    </a>
                    {/* Team Content */}
                    <div className="team-content mt-3 ">
                        <a href="https://t.me/Seyed3yed">
                            <h4 className="mb-0">ⓢⓗⓐⓗⓐⓑ</h4>
                        </a>
                        <span className="d-inline-block mt-2 mb-3">Tester Mafia</span>
                        {/* Social Share */}
                        <div className="social-share">
                            <ul className="d-flex justify-content-center list-unstyled">
                                <li>
                                    <a href="https://t.me/Seyed3yed">
                                        @Seyed3yed
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card team-card text-center m-2 col-12 col-md-6 col-lg-4">
                    <a  href="https://t.me/NIksA2020">
                        <img className="mx-auto" src="./../woman.png" alt />
                    </a>
                    {/* Team Content */}
                    <div className="team-content mt-3 ">
                        <a href="https://t.me/NIksA2020">
                            <h4 className="mb-0">𝐍𝐞𝐠𝐚𝐫</h4>
                        </a>
                        <span className="d-inline-block mt-2 mb-3">Tester Mafia</span>
                        {/* Social Share */}
                        <div className="social-share">
                            <ul className="d-flex justify-content-center list-unstyled">
                                <li>
                                    <a href="https://t.me/NIksA2020">
                                        @NIksA2020
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                

            </div></div>
    </section>
    );
}

export default Team;