const JoinBot = () => {
    return ( <section className="cta-area p-0" style={{direction:'rtl',textAlign :'right'}}>
  <div className="container">
    <div className="row">
      <div className="col-12 card">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-5 text-center">
            <img src="assets/img/content/cta_thumb.png" alt />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <h2 className="m-0">ورود به بات</h2>
            <p>برای ورود به ربات دکمه زیر را لمس یا کلیک کنید.</p>
            <a className="btn btn-bordered active d-inline-block" style={{direction:'ltr'}} href="https://t.me/hlproxyBot"><i className="icon-rocket mr-2" />ورود به ربات</a>
          </div>
        </div>
        <a className="cta-link" href="apply.html" />
      </div>
    </div>
  </div>
</section>
 );
}
 
export default JoinBot;