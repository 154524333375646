import { Fragment } from "react";

const MafiaCharecter = () => {
    return (<Fragment>
        <section className="wallet-connect-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/* Intro */}
                        <div className="intro text-center">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">نقش های مافیا</h3>
                                <p>مافیا از این نقش های اصلی تشکیل شده است</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center items">
                <div className="col-12 col-md-6 col-lg-4 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/godfather.png" alt />
                                <h4 className="mb-0">رئیس مافیا یا پدرخوانده</h4>
                                <p>نفر اصلی مافیاست و شلیک شب به دست اون انجام میشه
                                    پدرخوانده یک جلیقه یا همون جون اضافه داره که در صورت شلیک تک تیرانداز با تیر اول از بازی خارج نمیشه‌
                                    استعلام پدرخوانده برای کارگاه همیشه منفی میشه</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/villain.png" alt />
                                <h4 className="mb-0">دکتر لکتر</h4>
                                <p>دکتر مافیاهاست
                                    لکتر هر شب یکی از هم تیمی هاش رو نجات میده
                                    خودش رو یکبار و بقیه هم تیمی هاش با رعایت غیرتکراری بودن هرچند بار نجات میده</p>
                                <p/>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/broom.png" alt />
                                <h4 className="mb-0">شعبده باز</h4>
                                <p>یک عضو مافیاست که هر شب میتونه  توانایی یکی از شهروند هارو ازش بگیره
                                    شعبده باز دوشب پشت هم نمیتونه یک شهروند رو مسدود کنه</p>
                            </a>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/detective.png" alt />
                                <h4 className="mb-0">کارگاه</h4>
                                <p> هر شب میتونه استعلام یک بازیکن رو بگیره که اگر از تیم مافیا باشه استعلام مثبت و اگر شهر باشه منفی میشه
                                    استعلام رئیس مافیا همیشه منفی میشه</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/doctor.png" alt />
                                <h4 className="mb-0">دکتر شهر</h4>
                                <p>دکتر هر شب یک نفرو از شلیک مافیا نجات میده
                                    خودش رو یکبار و بقیه همشهری هاش رو هر چند بار با رعایت تکراری نبودن میتونه نجات بده</p>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 item">
                        {/* Single Wallet */}
                        <div className="card single-wallet">
                            <a className="d-block text-center" href="login.html">
                                <img className="avatar-lg" src="assets/img/mafiaCharecter/sniper.png" alt />
                                <h4 className="mb-0">تک تیرانداز</h4>
                                <p>دو تیر در اسلحش داره که میتونه با شناسایی مافیا در شب بهشون شلیک کنه
                                    اگر به اشتباه همشهری هاش رو زد خودش از بازی خارج میشه</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Fragment>);
}

export default MafiaCharecter;